.gallery-page {
  height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image-container {
    flex: 0 0 1000px;
    text-align: center;

    h1 {
      margin-bottom: 32px;
      color: $infi-orange;
    }

    img {
      width: 1000px;
      transition: transform 0.5s ease-in-out;
    }
  }

  .events {
    flex: 0 1 auto;

    .event:not(:last-child) {
      margin-bottom: 32px;
    }

    .title {
      color: $infi-orange;
      font-size: 2rem;
      font-weight: bold;
    }

    .subtitle {
      font-size: 1.5rem;
      color: $grey-light;
    }
  }
}
