$infi-orange: #f77803;
$infi-black: #12121c;
$white: #fff;
$grey-light: hsl(0, 0%, 71%);

html,
body {
  margin: 0;
  height: 100vh;
  background-color: $infi-black;
  color: $white;
  font-family: sans-serif;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

@import 'pages/GalleryPage';
